import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { PagesPressBanner } from 'types/generated/contentful-types'
import { ContentfulMetadataMock } from '@/utils/fixtures/ContentfulMetadataMock'

export type PressBannerProps = Omit<PagesPressBanner, '_id'>

export const pressBannerStaticData: PressBannerProps = {
  title: 'Sb',
  applyBackground: true,
  contentfulMetadata: ContentfulMetadataMock,
  logoSize: false,
  sys: {
    id: '',
    environmentId: '',
    spaceId: '',
  },
  logosCollection: {
    limit: 0,
    skip: 0,
    total: 0,
    items: [
      {
        url: 'https://media.foratravel.com/image/upload/v1715949693/Boston-Globe_fxf0lp.svg',
        title: 'Boston Globe',
        description: 'Boston Globe',
        contentfulMetadata: ContentfulMetadataMock,
        sys: {
          id: '',
          environmentId: '',
          spaceId: '',
        },
      },
      {
        url: 'https://media.foratravel.com/image/upload/v1715949724/Business-Insider_wahvng.svg',
        title: 'Business Insider',
        description: 'Business Insider',
        contentfulMetadata: ContentfulMetadataMock,
        sys: {
          id: '',
          environmentId: '',
          spaceId: '',
        },
      },
      {
        url: 'https://media.foratravel.com/image/upload/v1715949764/New-York-Mag_dwqvti.svg',
        title: 'New York Magazine',
        description: 'New York Magazine',
        contentfulMetadata: ContentfulMetadataMock,
        sys: {
          id: '',
          environmentId: '',
          spaceId: '',
        },
      },
      {
        url: 'https://media.foratravel.com/image/upload/v1715949783/Real-Simple_rtgnfz.svg',
        title: 'Real Simple',
        description: 'Real Simple',
        contentfulMetadata: ContentfulMetadataMock,
        sys: {
          id: '',
          environmentId: '',
          spaceId: '',
        },
      },
      {
        url: 'https://media.foratravel.com/image/upload/v1715949837/Tech-Crunch_o7pn0a.svg',
        title: 'Tech Crunch',
        description: 'Tech Crunch',
        contentfulMetadata: ContentfulMetadataMock,
        sys: {
          id: '',
          environmentId: '',
          spaceId: '',
        },
      },
      {
        url: 'https://media.foratravel.com/image/upload/v1715949879/WSJ_eb7fyl.svg',
        title: 'WSJ',
        description: 'WSJ',
        contentfulMetadata: ContentfulMetadataMock,
        sys: {
          id: '',
          environmentId: '',
          spaceId: '',
        },
      },
    ],
  },
}

type SlidesProps = Pick<
  PressBannerProps,
  'logosCollection' | 'cloudinaryLogos'
> & {
  className?: string
  largeLogo: boolean
}

const Slides = ({
  logosCollection,
  cloudinaryLogos,
  className,
  largeLogo = false,
}: SlidesProps): JSX.Element => {
  if (!logosCollection && cloudinaryLogos.length < 1) return <></>
  const prioritizedLogos = cloudinaryLogos ?? logosCollection?.items
  return (
    <ul
      className={`logos-slider inline-flex items-center ${
        largeLogo ? 'h-[88px]' : 'h-[57px]'
      } w-max pb-2 mb-8 md:pb-0 md:mb-0 ${className}`}
    >
      {prioritizedLogos.map((image, index) => (
        <li
          key={index}
          className="inline-flex justify-center mx-3 my-3.5 lg:mx-6 xl:w-auto"
        >
          <Image
            src={image?.secure_url ?? image?.url ?? ''}
            alt={
              image?.metadata?.alt ||
              image?.description ||
              `Fora - ${image?.title || ''}`
            }
            width={largeLogo ? 107 : 61}
            height={largeLogo ? 72 : 40}
            style={{ mixBlendMode: 'darken' }}
            priority // Lazy loading causes rendering issues for infinitely looping slider
          />
        </li>
      ))}

      <style jsx>{`
        @keyframes autoplay {
          from {
            transform: translate3d(0%, 0, 0);
          }
          to {
            transform: translate3d(-100%, 0, 0);
          }
        }
        .logos-slider {
          backface-visibility: hidden;
          transform: translate3d(0, 0, 0);
          animation: ${prioritizedLogos.length * 3}s autoplay infinite linear;
        }
      `}</style>
    </ul>
  )
}

const PressBanner = ({
  sys,
  title,
  applyBackground = true,
  logosCollection,
  cloudinaryLogos,
  redirectSlug = '',
  logoSize = false,
}: PressBannerProps): JSX.Element => {
  const headerStyles =
    'text-center uppercase fora-text-eyebrow-4 text-stone mb-4 md:mb-6'
  const largeLogo = logoSize

  return (
    <>
      {redirectSlug ? (
        <Link href={redirectSlug} legacyBehavior>
          <a id={sys?.id} className="block">
            <h3 className={headerStyles}>{title}</h3>
            <PressBannerContentBlock
              largeLogo={largeLogo as boolean}
              applyBackground={applyBackground}
              logosCollection={logosCollection}
              cloudinaryLogos={cloudinaryLogos}
            />
          </a>
        </Link>
      ) : (
        <div id={sys?.id} className="block">
          <h3 className={`${headerStyles} cursor-default`}>{title}</h3>
          <PressBannerContentBlock
            largeLogo={largeLogo as boolean}
            applyBackground={applyBackground}
            logosCollection={logosCollection}
            cloudinaryLogos={cloudinaryLogos}
          />
        </div>
      )}
    </>
  )
}

type PressBannerContentBlockProps = Pick<
  PressBannerProps,
  'logosCollection' | 'cloudinaryLogos'
> & {
  applyBackground: boolean | null
  largeLogo: boolean
}

const PressBannerContentBlock = ({
  applyBackground,
  logosCollection,
  cloudinaryLogos,
  largeLogo,
}: PressBannerContentBlockProps): JSX.Element => {
  return (
    <>
      <section
        className={`overflow-hidden whitespace-nowrap ${
          largeLogo ? 'h-[88px]' : 'h-[57px]'
        }`}
      >
        <div className="lg:w-screen lg:absolute lg:left-0 lg:overflow-hidden">
          <Slides
            largeLogo={largeLogo}
            cloudinaryLogos={cloudinaryLogos}
            logosCollection={logosCollection}
            className={applyBackground ? 'bg-darkSand' : ''}
          />
          {/* Triplicate slides to loop seamlessly */}
          <Slides
            largeLogo={largeLogo}
            cloudinaryLogos={cloudinaryLogos}
            logosCollection={logosCollection}
            className={`${applyBackground ? 'bg-darkSand' : ''}`}
          />
          <Slides
            largeLogo={largeLogo}
            cloudinaryLogos={cloudinaryLogos}
            logosCollection={logosCollection}
            className={`${applyBackground ? 'bg-darkSand' : ''}`}
          />
        </div>
      </section>
    </>
  )
}

export default PressBanner
